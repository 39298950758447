@import url("https://fonts.googleapis.com/css2?family=Heebo:wght@100;200;300;400;500;600;700;800;900&display=swap");
@import url("https://fonts.googleapis.com/css2?family=Assistant:wght@100;200;300;400;500;600;700;800;900");
@import url("https://fonts.googleapis.com/css2?family=Poppins:wght@100;200;300;400;500;600;700;800;900&display=swap");
@tailwind base;
@tailwind components;
@tailwind utilities;

@layer components {
    .cc-input {
        @apply w-full bg-green-50 py-2 px-1.5 rounded-md focus:outline-none;
    }
    .scorllbar {
        @apply [&::-webkit-scrollbar]:w-2 [&::-webkit-scrollbar]:h-2.5 [&::-webkit-scrollbar-thumb]:rounded-xl [&::-webkit-scrollbar-track]:bg-gray-100 [&::-webkit-scrollbar-thumb]:bg-gray-300;
    }

    .btn-primary {
        @apply flex gap-2 items-center justify-center text-lg px-4 py-[3px] h-[34px] w-full font-semibold text-center rounded-full shadow-sm text-white bg-green-500 hover:bg-green-600 disabled:bg-slate-300 disabled:cursor-not-allowed;
    }
    .btn-primary-lg {
        @apply flex gap-2 items-center justify-center text-2xl px-4 py-2 w-full font-semibold text-center rounded-full shadow-sm text-white bg-green-500 hover:bg-green-600 disabled:bg-slate-300 disabled:cursor-not-allowed;
    }
    .btn-outline {
        @apply flex gap-2 items-center justify-center text-lg px-4 py-[3px] h-[34px] w-full font-medium text-center rounded-full shadow-sm text-green-500 bg-white border border-green-500 hover:bg-green-50 disabled:bg-slate-300 disabled:cursor-not-allowed;
    }
}

@layer utilities {
    /* Hide scrollbar for Chrome, Safari and Opera */
    .no-scrollbar::-webkit-scrollbar {
        display: none;
    }
    /* Hide scrollbar for IE, Edge and Firefox */
    .no-scrollbar {
        -ms-overflow-style: none; /* IE and Edge */
        scrollbar-width: none; /* Firefox */
    }
    .no-select {
        user-select: none; /* Non-standard */
        -webkit-user-select: none; /* Safari */
        -moz-user-select: none; /* Firefox */
        -ms-user-select: none; /* Internet Explorer/Edge */
    }
}

button,
a,
label,
li, div {
    -webkit-tap-highlight-color: transparent;
}

@tailwind base;
@tailwind components;
@tailwind utilities;

@keyframes fadeInFromLeft {
    0% {
        opacity: 0;
        transform: translateX(-20px);
    }
    100% {
        opacity: 1;
        transform: translateX(0);
    }
}

:root {
    --animation-delay: 0.055s;
}

.parent-fade-in-left > * {
    opacity: 0;
    animation: fadeInFromLeft 0.3s ease-out forwards;
}

/* .parent-fade-in-left > *:nth-child(1) {
  animation-delay: calc(var(--animation-delay) * 1);
} */

.parent-fade-in-left > *:nth-child(2) {
    animation-delay: calc(var(--animation-delay) * 1);
}

.parent-fade-in-left > *:nth-child(3) {
    animation-delay: calc(var(--animation-delay) * 2);
}

.parent-fade-in-left > *:nth-child(4) {
    animation-delay: calc(var(--animation-delay) * 3);
}

.Toastify__toast {
    min-height: 36px !important;
    opacity: 0.85 !important;
}
.Toastify__toast--rtl {
    min-height: 36px !important;
    opacity: 0.85 !important;
}
.Toastify__toast-body {
    min-height: 36px !important;
    opacity: 0.85 !important;
}
